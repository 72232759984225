import { IconProps } from "./type";

const commonProps = {
  className: "sidebar-item",
  width: "12",
  height: "12",
  viewBox: "0 0 12 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
};

export const NotStartedIcon = (props: IconProps) => {
  return (
    <svg {...commonProps} {...props}>
      <path d="M6.25 3.125C6.25 3.47656 5.95703 3.75 5.625 3.75C5.27344 3.75 5 3.47656 5 3.125V1.875C5 1.54297 5.27344 1.25 5.625 1.25C5.95703 1.25 6.25 1.54297 6.25 1.875V3.125ZM6.25 10.625C6.25 10.9766 5.95703 11.25 5.625 11.25C5.27344 11.25 5 10.9766 5 10.625V9.375C5 9.04297 5.27344 8.75 5.625 8.75C5.95703 8.75 6.25 9.04297 6.25 9.375V10.625ZM0.625 6.25C0.625 5.91797 0.898438 5.625 1.25 5.625H2.5C2.83203 5.625 3.125 5.91797 3.125 6.25C3.125 6.60156 2.83203 6.875 2.5 6.875H1.25C0.898438 6.875 0.625 6.60156 0.625 6.25ZM10 5.625C10.332 5.625 10.625 5.91797 10.625 6.25C10.625 6.60156 10.332 6.875 10 6.875H8.75C8.39844 6.875 8.125 6.60156 8.125 6.25C8.125 5.91797 8.39844 5.625 8.75 5.625H10ZM2.07031 2.71484C2.32422 2.48047 2.71484 2.48047 2.96875 2.71484L3.84766 3.61328C4.08203 3.84766 4.08203 4.23828 3.84766 4.49219C3.59375 4.72656 3.20312 4.72656 2.96875 4.49219L2.07031 3.61328C1.83594 3.35938 1.83594 2.96875 2.07031 2.71484ZM9.16016 8.90625C9.39453 9.16016 9.39453 9.55078 9.16016 9.78516C8.90625 10.0391 8.51562 10.0391 8.26172 9.78516L7.38281 8.90625C7.14844 8.67188 7.14844 8.26172 7.38281 8.02734C7.63672 7.77344 8.02734 7.77344 8.26172 8.02734L9.16016 8.90625ZM2.07031 9.78516C1.83594 9.55078 1.83594 9.16016 2.07031 8.90625L2.96875 8.02734C3.20312 7.77344 3.59375 7.77344 3.84766 8.02734C4.08203 8.26172 4.08203 8.67188 3.84766 8.90625L2.96875 9.78516C2.71484 10.0391 2.32422 10.0391 2.07031 9.78516ZM8.26172 2.71484C8.51562 2.48047 8.90625 2.48047 9.16016 2.71484C9.39453 2.96875 9.39453 3.35938 9.16016 3.61328L8.26172 4.49219C8.02734 4.72656 7.63672 4.72656 7.38281 4.49219C7.14844 4.23828 7.14844 3.84766 7.38281 3.61328L8.26172 2.71484Z" />
    </svg>
  );
};

export const InProgressIcon = (props: IconProps) => {
  return (
    <svg {...commonProps} {...props}>
      <path d="M11 6.25C11 9.02344 8.75391 11.25 6 11.25C3.22656 11.25 1 9.02344 1 6.25C1 3.49609 3.22656 1.25 6 1.25C8.75391 1.25 11 3.49609 11 6.25Z" />
    </svg>
  );
};

export const CompletedIcon = (props: IconProps) => {
  return (
    <svg {...commonProps} {...props}>
      <path d="M1 6.25C1 3.49609 3.22656 1.25 6 1.25C8.75391 1.25 11 3.49609 11 6.25C11 9.02344 8.75391 11.25 6 11.25C3.22656 11.25 1 9.02344 1 6.25ZM8.24609 5.39062C8.46094 5.17578 8.46094 4.84375 8.24609 4.62891C8.03125 4.41406 7.69922 4.41406 7.48438 4.62891L5.375 6.73828L4.49609 5.87891C4.28125 5.66406 3.94922 5.66406 3.73438 5.87891C3.51953 6.09375 3.51953 6.42578 3.73438 6.64062L4.98438 7.89062C5.19922 8.10547 5.53125 8.10547 5.74609 7.89062L8.24609 5.39062Z" />
    </svg>
  );
};

export enum POSITION_ENUM {
  ASSOCIATE_DEPUTY_MINISTER = 1,
  ADM = 2,
  EXECUTIVE_PROJECT_DIRECTOR = 3,
  PROJECT_ASSESSMENT_DIRECTOR = 4,
  PROJECT_ASSESSMENT_OFFICER = 5,
  PROJECT_ANALYST = 6,
  OTHER = 7,
  MINISTER = 8,
}
